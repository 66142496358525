//这里需要引入vuex
import store from '../store';

let wsConnection = {
    $ws: null,
    lockReturn: false,
    timeout: 20 * 1000,
    timeoutObj: null,
    timeoutNum: null,
    serverTimeoutObj: null,
    weboscket_callback: null,
    ifReconnect: true,
    //初始化webSocket长连接
    initWebSocket: function () {
        if(!store.state.loginInfos.ws_url) return
        let url = ''
        if(window.parent.frames['agIframe'] && !store.state.isJsIntegrated) {
            url = `${store.state.loginInfos.ws_url}?token=${store.state.loginInfos.token}`
        } else {
            url = store.state.loginInfos.ws_url
        }
        this.$ws = new WebSocket(url);//写入地址 这里的地址可以在initWebSocket方法加入参数
        this.$ws.onopen = this.wsOpen;
        this.$ws.onclose = this.wsClose;
        this.$ws.onmessage = this.wsMsg;
        this.$ws.onerror = this.wsError;
    },
    //打开websocket
    wsOpen: function (e) {
        //开始websocket心跳
        wsConnection.startWsHeartbeat();
        console.log('ws success')
    },
    wsClose: function (e) {
        console.log(e, 'ws close')
        if(e.code == 1005) {
            wsConnection.stopHeartbeat()
            wsConnection.$ws.close()
        }
        if(wsConnection.ifReconnect && e.code != 1005) {
            wsConnection.reconnect()
        }
    },
    wsMsg: function (e) {
        let res = JSON.parse(e.data)
        // console.log('socket', res)
        // 每次接收到服务端消息后 重置websocket心跳
        if(res.result!=100001) {
            wsConnection.ifReconnect = true
            wsConnection.resetHeartbeat();
        } else {
            wsConnection.ifReconnect = false
            wsConnection.$ws.close()
            wsConnection.timeoutNum && clearTimeout(wsConnection.timeoutNum);
            wsConnection.timeoutObj && clearTimeout(wsConnection.timeoutObj);
            wsConnection.serverTimeoutObj && clearTimeout(wsConnection.serverTimeoutObj);
            wsConnection.$ws = null
        }
        // 卖家成功提交卖币后
        if (res.result == 30000) {
            store.dispatch('saveSellInfosActions', res.data)
        }
        // 买家购买后一系列操作，卖家收到订单消息
        if (res.result == 40000) {
            store.dispatch('saveOrderRemindActions', res.data)
        }

        if (res.result == 50000) {
            console.log('socket', res)
            store.dispatch('saveOrderRemindActions', res.data)
            //  比特币充值/提现处理
            // if (res.data.name === 'bitcoin') {
            //     if (res.data.type === 1) {
            //         console.log('比特币充值')
            //     }
            //     if (res.data.type === 2) {
            //         console.log('比特币提现')
            //     }
            // } else {
            //     // 卖家确认订单后一系列操作，买家收到订单消息
            //     store.dispatch('saveOrderRemindActions', res.data)
            // }
        }
        // 完成交易后，买卖双方收到消息，以便首页金额变化
        if (res.result == 60000) {
            store.dispatch('saveMoneyChangeActions', res.data)
        }
        // 后台审核已实名
        if (res.result == 70000) {
            store.dispatch('saveIsNameActions', true)
        }
        //  获取火币网实时汇率
        if (res.result == 80000) {
            store.commit('setRealTimeRate', res.data.rate)
        }
    },
    wsError: function (err) {
        console.log(err, 'ws error');
    },
    // 发送数据
    sendData: (data,callback) => {
        wsConnection.weboscket_callback = callback
        let data1 = null
        //  判断 data 数据类型
        if (typeof data == 'string') {
            data1 = {...JSON.parse(data), "ext":{"from":2}}
        } else {
            data1 = {...data, "ext":{"from":2}}
        }
        data1 = JSON.stringify(data1)
        console.log(wsConnection.$ws);
        //  判断 websocket 的状态
        if (wsConnection.$ws && wsConnection.$ws.readyState == wsConnection.$ws.OPEN) {
            // 已经打开,可以直接发送
            wsConnection.$ws.send(data1)
        } else if (wsConnection.$ws && wsConnection.$ws.readyState == wsConnection.$ws.CONNECTING) {
            // 正在开启状态中,则 1 秒后重新发送
            setTimeout(() => {
                wsConnection.$ws.send(data1)
            }, 1000)
        } else {
            // 未打开，则开启后重新调用
            wsConnection.initWebSocket()
            wsConnection.sendData(data1,callback)
        }
    },
    //重启websocket
    reconnect: function () {
        let _this = this;
        if (_this.lockReturn) {
            return;
        }
        _this.lockReturn = true;
        _this.timeoutNum && clearTimeout(_this.timeoutNum);
        _this.timeoutObj && clearTimeout(_this.timeoutObj);
        _this.serverTimeoutObj && clearTimeout(_this.serverTimeoutObj);
        _this.$ws = null
        _this.timeoutNum = setTimeout(function () {
            _this.initWebSocket();
            _this.lockReturn = false;
        }, 3000);
    },
    startWsHeartbeat: function () {
        let _this = this;
        _this.timeoutObj && clearTimeout(_this.timeoutObj);
        _this.serverTimeoutObj && clearTimeout(_this.serverTimeoutObj);
        _this.timeoutObj = setInterval(function () {
            if (_this.$ws.readyState === 1) {
                _this.$ws.send(JSON.stringify({"cmd":"index.heartbeat","data":{message:"ping",uid:store.state.loginInfos.uid,token:store.state.loginInfos.token,action:'games'}}));
            }
        }, _this.timeout);
    },
    //重置websocket心跳
    resetHeartbeat: function () {
        let _this = this;
        clearTimeout(_this.timeoutObj);
        clearTimeout(_this.serverTimeoutObj);
        _this.startWsHeartbeat()
    },
    //停止websocket心跳
    stopHeartbeat: function () {
        let _this = this;
        clearTimeout(_this.timeoutObj);
        clearTimeout(_this.serverTimeoutObj);
    }
};

//抛出websocket对象
export default wsConnection
