import axios from 'axios'
import router from '../router/index'
import { Toast } from 'vant';
import wsConnection from "@/utils/websocket"
import store from '../store';
import Fingerprint2 from 'fingerprintjs2';

// const userAgent = navigator.userAgent
// const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1;
// const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

// if(isAndroid && !isIOS) {
//     axios.defaults.headers['terminal'] = 1;//配置请求头
// }
// if(!isAndroid && isIOS) {
//     axios.defaults.headers['terminal'] = 2;//配置请求头
// }
// if(!isAndroid && !isIOS) {
//     axios.defaults.headers['terminal'] = 1;//配置请求头
// }
let deviceNo = localStorage.getItem('deviceNo') || '';
if (!deviceNo) {
    const createFingerprint = () => {
        // 浏览器指纹
        // eslint-disable-next-line no-unused-vars
        const fingerprint = Fingerprint2.get((components) => { // 参数只有回调函数时，默认浏览器指纹依据全部配置信息进行生成
            const values = components.map(component => component.value); // 配置的值的数组
            deviceNo = Fingerprint2.x64hash128(values.join(''), 31); // 生成浏览器指纹
            localStorage.setItem('deviceNo', deviceNo); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
        });
    };

    if (window.requestIdleCallback) {
        requestIdleCallback(() => {
            createFingerprint();
        });
    } else {
        setTimeout(() => {
            createFingerprint();
        }, 500);
    }
}

axios.defaults.headers['terminal'] = 3;//配置请求头
axios.defaults.baseURL = BASE_DEFINED_URL

axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';//配置请求头

// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
    let token = store.state.loginInfos.token
    if (token) {
        config.headers['access-token'] = token;
    }
    config.headers['devicenumber'] = deviceNo; // 设备号（浏览器指纹）

    return config;
}, function (error) {
    Toast.fail('请求超时');
    // Do something with request error
    return Promise.reject(error);
});

// 添加一个响应拦截器
axios.interceptors.response.use(function (response) {
    if (response.data) {
        // 100001: 账号被挤掉, token失效,  101121: 账号冻结
        if ([100001, 101121].includes(response.data.result)) {
            Toast.fail(response.data.msg)
            localStorage.removeItem("loginInfos")
            if(wsConnection.$ws) {
                wsConnection.$ws.close()
            }
            // 触发综合
            if(window.parent.frames['agIframe'] && !store.state.isJsIntegrated) {
                window.parent.postMessage('退出登录', store.state.loginInfos.originUrl)
            } else if (store.state.isJsIntegrated) {
                router.push('/jsIntegratedLogin');
            } else {
                router.push('/login');
            }
        }

        // 维护
        if (response.data.result === 30000) {
            localStorage.removeItem("loginInfos")
            if(wsConnection.$ws) {
                wsConnection.$ws.close()
            }
            router.push('/maintenance');
        }
    }
    return response;
}, function (error) {
    Toast.fail("服务器链接失败");
    // 错误日志传给后台
    // post(`${BASE_DEFINED_URL}/index/upload_error`,{info:JSON.parse(JSON.stringify(error))})
    return Promise.reject(error);
})

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: {
                ...params
            },
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(`${url}`, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}

// 对外暴露
export { post, get }
